import React, { FunctionComponent } from "react"
import { SearchResults } from "@features/search/components/search-results/search-results"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { PageProps } from "@src-types/page"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"

export const SearchResultsPage: FunctionComponent<PageProps> = (props) => {
  const { metadata } = useIndexPageData("search")

  return (
    <Page
      {...props}
      metadata={metadata}
      pageOptions={{ breadcrumbs: PageBreadcrumbs.Search }}
    >
      <SearchResults defaultStyleSheet={false} variant="fullcards" />
    </Page>
  )
}

export default SearchResultsPage
